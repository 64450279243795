import { Cloud, Edition, TrialType, AwsMpAccount } from '@signup/shared';
import queryString from 'query-string';
import * as _ from 'lodash';
import * as configs from './configurations';

function getLastParam(param: string | string[]) {
  if (Array.isArray(param)) {
    return param[param.length - 1];
  } else {
    return param;
  }
}

enum SignupUrlParameter {
  PLAN_PREFERENCE = 'planp',
  PLAN = 'plan',
  CLOUD_PREFERENCE = 'cloudp',
  CLOUD = 'cloud',
  REGION_PREFERENCE = 'regionp',
  REGION = 'region',
  OWNER = 'owner',
  ENG = 'eng',
  AMPT = 'ampt',
  SDM = 'SDM',
  TRIAL = 'trial',
  LISTING = 'listing',
  REFERRER = 'referrer',
  ABEXPERIMENT = 'abexperiment',
  ACCOUNT = 'account',
  AWSMP = 'awsmp',
  LAB = 'lab',
  TUTORIAL = 'tutorial',
}

enum MarketingUrlParameter {
  UTM_ADGROUP = 'utm_adgroup',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
  UTM_REGION = 'utm_region',
  GCLID = 'gclid',
}

export interface MarketingParams {
  [MarketingUrlParameter.UTM_ADGROUP]?: string;
  [MarketingUrlParameter.UTM_SOURCE]?: string;
  [MarketingUrlParameter.UTM_MEDIUM]?: string;
  [MarketingUrlParameter.UTM_CAMPAIGN]?: string;
  [MarketingUrlParameter.UTM_CONTENT]?: string;
  [MarketingUrlParameter.UTM_TERM]?: string;
  [MarketingUrlParameter.UTM_REGION]?: string;
  [MarketingUrlParameter.GCLID]?: string;
}

export interface SignupParams {
  plan?: Edition;
  planp?: Edition;
  cloud?: Cloud;
  cloudp?: Cloud;
  region?: string;
  regionp?: string;
  owner?: string;
  eng?: string; // Sales Engineer Email
  ampt?: string; // Amazon Marketplace Token
  sdm?: boolean; // Snowflake Data Marketplace
  developers?: boolean; // Is a `/developers` signup
  trialType?: TrialType; // Type of trial, e.g. competition, student
  listing?: string; //global name of marketplace listing
  referrer?: string; //where did the signup come from (ex. snowsight)
  abexperiment?: string; // for guranteeing inclusion in an experiment
  account?: string; // Encrypted SFDC account ID the signup is associated with
  awsmp?: AwsMpAccount; // AWS Marketplace account the request originated from
  lab?: string; //id of the lab where the user initiated the trial experience
  polaris?: boolean; // Is a `/polaris` signup
  tutorial?: string; //id of tutorial the user will land at
}

export const parseQueryParams = (
  paramString: string | undefined,
): [SignupParams, MarketingParams] => {
  const filteredSignupParams: SignupParams = {};
  let marketingParams: MarketingParams = {};

  if (paramString) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rawParams: any = _.mapValues(
      queryString.parse(paramString),
      (values: string | string[]) => getLastParam(values),
    );

    marketingParams = _.pick(rawParams, [
      MarketingUrlParameter.UTM_ADGROUP,
      MarketingUrlParameter.UTM_SOURCE,
      MarketingUrlParameter.UTM_MEDIUM,
      MarketingUrlParameter.UTM_CAMPAIGN,
      MarketingUrlParameter.UTM_CONTENT,
      MarketingUrlParameter.UTM_TERM,
      MarketingUrlParameter.UTM_REGION,
      MarketingUrlParameter.GCLID,
    ]);

    //NOTE: the param values differ from the Snowflake edition names
    const paramToEditionMapping: { [key: string]: Edition } = {
      standard: Edition.STANDARD,
      enterprise: Edition.ENTERPRISE,
      business_critical: Edition.BUSINESS_CRITICAL,
      enterprise_sensitive: Edition.BUSINESS_CRITICAL,
    };

    const handlePlanParam = (value: any) => {
      if (value) {
        const edition = paramToEditionMapping[value.toLowerCase()];
        if (edition) {
          filteredSignupParams[SignupUrlParameter.PLAN] = edition;
        }
      }
    };

    const handlePlanPreferenceParam = (value: any) => {
      if (value) {
        const edition = paramToEditionMapping[value.toLowerCase()];
        if (edition) {
          filteredSignupParams[SignupUrlParameter.PLAN_PREFERENCE] = edition;
        }
      }
    };

    const handleCloudParam = (value: any) => {
      if (Object.values(Cloud).some(e => e === value)) {
        filteredSignupParams[SignupUrlParameter.CLOUD] = value as Cloud;
      }
      if (value?.toLowerCase() === 'sdm') {
        filteredSignupParams['sdm'] = true;
      }
    };

    const handleCloudPreferenceParam = (value: any) => {
      if (Object.values(Cloud).some(e => e === value)) {
        filteredSignupParams[SignupUrlParameter.CLOUD_PREFERENCE] = value as Cloud;
      }
    };

    const handleEngParam = (value: any) => {
      filteredSignupParams[SignupUrlParameter.ENG] = value.trim();
    };

    const handleOwnerParam = (value: any) => {
      // Salesforce field length restriction means this can only ever be 64 characters long
      filteredSignupParams[SignupUrlParameter.OWNER] = value.trim().substring(0, 64);
    };

    const handleAmptParam = (value: any) => {
      filteredSignupParams[SignupUrlParameter.AMPT] = decodeURIComponent(value);
    };

    const handleSdmParam = (value: any) => {
      filteredSignupParams['sdm'] = true;
      filteredSignupParams[SignupUrlParameter.OWNER] = `${value}-referral`;
    };

    const handleTrialParam = (value: any) => {
      const lowerCaseValue = (value && value.toLowerCase && value.toLowerCase()) || '';

      if (lowerCaseValue === TrialType.COMPETITION) {
        filteredSignupParams.trialType = TrialType.COMPETITION;
      }

      if (lowerCaseValue === TrialType.STUDENT) {
        filteredSignupParams.trialType = TrialType.STUDENT;
      }

      if (lowerCaseValue === TrialType.TRIAL_LEARN) {
        filteredSignupParams.trialType = TrialType.TRIAL_LEARN;
      }

      if (lowerCaseValue === TrialType.DATA_SCIENCE_COMPETITION_JP) {
        filteredSignupParams.trialType = TrialType.DATA_SCIENCE_COMPETITION_JP;
      }

      if (lowerCaseValue === TrialType.STARTUP) {
        filteredSignupParams.trialType = TrialType.STARTUP;
      }
    };

    const handleListingParam = (value: any) => {
      filteredSignupParams[SignupUrlParameter.LISTING] = value;
    };

    const handleRegionParam = (value: any) => {
      const deployments = configs.getDeployments();
      const cloud = deployments[value]?.cloud;
      if (cloud != undefined) {
        // always lock in the cloud based on region, and either default or lock in region
        filteredSignupParams[SignupUrlParameter.CLOUD] = cloud;
        filteredSignupParams[SignupUrlParameter.REGION] = value;
      }
    };

    const handleRegionPreferenceParam = (value: any) => {
      const deployments = configs.getDeployments();
      const cloud = deployments[value]?.cloud;
      if (cloud != undefined) {
        // always lock in the cloud based on region, and either default or lock in region
        filteredSignupParams[SignupUrlParameter.CLOUD] = cloud;
        filteredSignupParams[SignupUrlParameter.REGION_PREFERENCE] = value;
      }
    };

    const handleReferrer = (value: any) => {
      filteredSignupParams[SignupUrlParameter.REFERRER] = value;
    };

    const handleAbExperiment = (value: any) => {
      filteredSignupParams[SignupUrlParameter.ABEXPERIMENT] = value;
    };

    const handleAccountParam = (value: string) => {
      filteredSignupParams[SignupUrlParameter.ACCOUNT] = value;
    };

    const handleAwsMpParam = (value: string) => {
      if (Object.values(AwsMpAccount).some(str => str === value)) {
        filteredSignupParams[SignupUrlParameter.AWSMP] = value as AwsMpAccount;
      }
    };

    const handleLabParam = (value: any) => {
      filteredSignupParams[SignupUrlParameter.LAB] = value;
    };

    const handleTutorialParam = (value: any) => {
      filteredSignupParams[SignupUrlParameter.TUTORIAL] = value;
    };

    //IMPORTANT: Insertion order into the map matters! (ex. check region after
    //cloud, and regionp before region)
    const signupParamToHandlerMapping = new Map([
      [SignupUrlParameter.PLAN_PREFERENCE, handlePlanPreferenceParam],
      [SignupUrlParameter.PLAN, handlePlanParam],
      [SignupUrlParameter.CLOUD_PREFERENCE, handleCloudPreferenceParam],
      [SignupUrlParameter.CLOUD, handleCloudParam],
      [SignupUrlParameter.REGION_PREFERENCE, handleRegionPreferenceParam],
      [SignupUrlParameter.REGION, handleRegionParam],
      [SignupUrlParameter.OWNER, handleOwnerParam],
      [SignupUrlParameter.ENG, handleEngParam],
      [SignupUrlParameter.AMPT, handleAmptParam],
      [SignupUrlParameter.SDM, handleSdmParam],
      [SignupUrlParameter.TRIAL, handleTrialParam],
      [SignupUrlParameter.LISTING, handleListingParam],
      [SignupUrlParameter.REFERRER, handleReferrer],
      [SignupUrlParameter.ABEXPERIMENT, handleAbExperiment],
      [SignupUrlParameter.ACCOUNT, handleAccountParam],
      [SignupUrlParameter.AWSMP, handleAwsMpParam],
      [SignupUrlParameter.LAB, handleLabParam],
      [SignupUrlParameter.TUTORIAL, handleTutorialParam],
    ]);

    signupParamToHandlerMapping.forEach((handler, param) => {
      const value = rawParams[param];
      if (value) {
        handler(value);
      }
    });
  }
  return [filteredSignupParams, marketingParams];
};
