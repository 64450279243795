import { Block, Color } from '@snowflake/core-ui';
import cookies from 'browser-cookies';
import React from 'react';

const MANUAL_OVERRIDE_COOKIE = '_expoverride';
let cookieValue: { [key: string]: string } | null = null;
try {
  cookieValue = JSON.parse(cookies.get(MANUAL_OVERRIDE_COOKIE) || '');
  if (typeof cookieValue !== 'object') {
    cookieValue = null;
  }
} catch {
  // ignore;
}

const clearCookie = (expName: string) => {
  if (cookieValue) {
    delete cookieValue[expName];
    cookies.set(MANUAL_OVERRIDE_COOKIE, JSON.stringify(cookieValue));
  } else {
    cookies.erase(MANUAL_OVERRIDE_COOKIE);
  }
  window.location.replace(window.location.origin + window.location.pathname);
};

export const ExperimentOverridesHeader = () => {
  return (
    <>
      {cookieValue
        ? Object.entries(cookieValue).map(([expName, groupName]) => (
            <Block
              key={expName}
              onClick={() => clearCookie(expName)}
              backgroundColor={Color.Blue50}
              color={Color.White}
              cursor="pointer"
              display="flex"
              height="20px"
              justifyContent="center"
              opacity={0.5}
              position="absolute"
              width="100%"
              zIndex={10}
            >
              Experiment override ({expName}): {groupName} (Click to Clear)
            </Block>
          ))
        : undefined}
    </>
  );
};
