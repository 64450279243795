import { Color, Checkbox, Column, Row, RowProps, Text } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export interface EmailOptInAgreementProps extends RowProps {
  optInEmailAgreement: boolean;
  optInAgreementOnChangeHandler: (optIn: boolean) => void;
}

export const EmailOptInAgreement = (props: EmailOptInAgreementProps) => {
  const { optInEmailAgreement, optInAgreementOnChangeHandler, ...restProps } = props;
  return (
    <Row align="left" verticalAlign="top" {...restProps} marginBottom={8} marginTop={12}>
      <Column align="left" verticalAlign="top" marginRight={8}>
        <Checkbox
          name="opt-in-agreement"
          aria-label="opt-in-agreement"
          checked={optInEmailAgreement}
          value="opt-in-agreement"
          onChange={() => optInAgreementOnChangeHandler(optInEmailAgreement)}
        />
      </Column>
      <Column align="left" verticalAlign="top">
        <Text size="small" color={Color.Gray70}>
          <FormattedMessage
            id="Yes, I do want Snowflake to send me e-mails about products, services, and events
                  that it thinks may interest me."
          />
        </Text>
      </Column>
    </Row>
  );
};
EmailOptInAgreement.displayName = 'EmailOptInAgreement';
